import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(private msalService: MsalService, private readonly router: Router) {}

  login() {
    this.msalService.loginPopup().subscribe((response) => {               
      this.router.navigate(['/']);      
    });
}
}