import { Component } from '@angular/core';

@Component({
  selector: 'app-multi-action-modal',
  templateUrl: './multi-action-modal.component.html',
  styleUrls: ['./multi-action-modal.component.scss']
})
export class MultiActionModalComponent {

}
