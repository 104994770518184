import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WriteoffReport } from '../models/writeoff-report';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class WriteoffReportService {
  constructor(private readonly httpClient: HttpClient, private readonly configService: ConfigService) {}

  protected readonly apiUrl = this.configService.get('WriteOffApiUrl'); 

  async getWriteoffReport(): Promise<WriteoffReport[]> {   
       return await this.httpClient.get<WriteoffReport[]>(`${this.apiUrl}/report/`).toPromise() || [];    
  }
}