import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { WriteoffReportService } from '../services/writeoff-report.service';
import {MatSort, Sort} from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { WriteoffReport } from '../models/writeoff-report';

@Component({
  selector: 'app-writeoff-report',
  templateUrl: './writeoff-report.component.html',
  styleUrls: ['./writeoff-report.component.scss']
})

export class WriteoffReportComponent implements OnInit {
  displayedColumns: string[] = ['id', 'created', 'numberOfWriteoffs', 'status', 'updatedAt', 'viewReport'];
  selection = new SelectionModel<WriteoffReport>(true, []);
  dataSource!: MatTableDataSource<WriteoffReport>;
  dataSourceWithPageSize = new MatTableDataSource<WriteoffReport>();
  errorMessage: string = '';
  isLoadingWriteoffReport: boolean = true;

  constructor(private route: ActivatedRoute, private readonly writeoffReportService: WriteoffReportService) { }

  @ViewChild('paginator') set paginator(paginator: MatPaginator) {
    if (!this.dataSource) { return; }
    this.dataSource.paginator = paginator;
  };
  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (!this.dataSource) { return; }
    this.dataSource.sort = sort;
  };

  async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      const data = await this.writeoffReportService.getWriteoffReport();
      data.forEach((element: any) => {
        element.numberOfWriteoffs = element.writeoffs && Array.isArray(element.writeoffs) ? element.writeoffs.length : 0;
      });

      this.dataSource = new MatTableDataSource<WriteoffReport>(data);  
      this.isLoadingWriteoffReport = false;

    } catch (error) {
      this.isLoadingWriteoffReport = false;
      console.error('Error loading data:', error);
      this.errorMessage = 'An error occurred while loading data. Please try again later.';
    }
  }
}