
<mat-toolbar id="writeoff-navbar" color="primary">
  <a id="lumeris-logo" routerLink="/"><img src="/assets/img/logo.png"/></a>
  <div *ngIf="isAuthenticated" class="nav-bar-item">
    <a class="title" href="/create"><mat-icon>add</mat-icon>New Report</a>
  </div>
  <div *ngIf="isAuthenticated" class="nav-bar-item">
    <a class="title" href="/"><mat-icon>list</mat-icon>Reports</a>
  </div>
  <div class="toolbar-spacer"></div>
  <a *ngIf="isAuthenticated">Welcome! {{activeUser}}</a>   
  <button mat-raised-button color="accent" (click)="logout()" *ngIf="isAuthenticated">Logout</button>
</mat-toolbar>
  

<router-outlet></router-outlet>