import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { jwtDecode } from 'jwt-decode';
import { ConfigService } from './config.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private readonly msalService: MsalService, private readonly configService: ConfigService) {}

  async hasRole(role: string): Promise<boolean> {
    const roles = await this.acquireFrontEndRoles();
    return roles.includes(role);
  }

  async acquireFrontEndRoles(): Promise<string[]> {
    const request = {
      scopes: [`api://${this.configService.get('WriteOffApiClientId')}/${this.configService.get('WriteOffApiScopes')[0]}`]
    };
  
    try {
      const response = await firstValueFrom(this.msalService.acquireTokenSilent(request));
      const apiToken = response.accessToken;

      const decodedToken = jwtDecode(apiToken) as any; 
      const roles = decodedToken['roles'] || [];
      return roles;
    } catch (error) {
      console.error('Failed to acquire token', error);
      throw error;
    }
  }
}