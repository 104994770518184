<div id="container">
    <div id="top-section">
        <mat-accordion>
            <mat-expansion-panel [expanded]="!isTopSectionCollapsed" (click)="toggleTopSection()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="left-content">
                            Report Details
                        </div>
                        <div class="right-content">
                            <div class="ReportApprovedWriteoffDetails">
                                <label>Number of Approved Writeoffs: </label>
                                <p>{{ totalNumberOfApprovedWriteoffs }}</p>
                            </div>
                            
                            <div class="ReportApprovedWriteoffDetails">
                                <label>Sum of Approved Writeoffs: </label>
                                <p>{{ totalSumOfApprovedWriteoffs | currency}}</p>
                            </div>
                        </div>
                    </mat-panel-title>
                    
                </mat-expansion-panel-header>
                <div id="spinner-container" *ngIf="isLoadingReportDetails; else reportDetailsSection">
                    <mat-spinner *ngIf="!loadingDataError"></mat-spinner>
                    <div class="error-message" *ngIf="loadingDataError">
                        <p>There was an error loading the report details. Please try again later.</p>
                    </div>
                </div>
                <ng-template #reportDetailsSection>
                    <div class="info-row">
                        <div class="info-content">
                            <div class="ReportDetails">
                                <label>Status: </label>
                                <p>{{reportDetails.status}}</p>
                            </div>
                            
                            <div class="ReportDetails">
                                <label>Last Updated By: </label>
                                <p>{{reportDetails.lastUpdatedBy.name}}</p>
                            </div>

                            <div class="ReportDetails">
                                <label>Created: </label>
                                <p>{{reportDetails.created | date:'yyyy-MM-dd h:mm a'}}</p>
                            </div>

                            <div class="ReportDetails">
                                <label>Updated: </label>
                                <p>{{reportDetails.updatedAt | date:'yyyy-MM-dd h:mm a'}}</p>
                            </div>
                        </div>
                        <div class="button-group">
                            <button *ngIf="isAdmin" mat-raised-button color="success" (click)="$event.stopPropagation()" (click)="applyReport()" [disabled]="isReportDenied" class="apply-report-button">Apply</button>
                            <button *ngIf="isAdmin" mat-raised-button color="warn" (click)="$event.stopPropagation()" (click)="confirmReportStatusUpdate(true)" [disabled]="isReportDenied" class="deny-report-button">Deny</button>
                            <button *ngIf="isReportDenied" mat-raised-button color="success" (click)="$event.stopPropagation()" (click)="confirmReportStatusUpdate(false)">Un-Deny</button>
                            <button mat-raised-button color="primary" (click)="$event.stopPropagation()" (click)="downloadToExcel()">Download Excel</button>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
     
    <div id="bottom-section">
        <div id="spinner-container" *ngIf="isLoadingWriteoffs; else writeoffsSection">
            <mat-spinner *ngIf="!loadingDataError"></mat-spinner>
            <div class="error-message" *ngIf="loadingDataError">
                <p>There was an error loading the report writeoffs. Please try again later.</p>
            </div>
        </div>
            <ng-template #writeoffsSection>
                <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? selection.toggle(row) : null"
                                            [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="subscriberId">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="header-container">
                                    <div mat-sort-header class="header-cell">
                                        <mat-icon (click)="$event.stopPropagation()" (click)="toggleInput('showSearchSubscriberId')">search</mat-icon>
                                        <ng-container *ngIf="!showSearchSubscriberId; else inputTemplateSubscriberId">
                                            Subscriber Id
                                        </ng-container>
                                        <ng-template #inputTemplateSubscriberId>
                                            <input #searchInputSubscriberId matInput (input)="updateFilterValue('subscriberId', $event)" placeholder="Search Subscribers">
                                        </ng-template>                     
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.subscriberId}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="header-container">
                                    <div mat-sort-header class="header-cell">
                                        
                                        <mat-icon (click)="$event.stopPropagation()" (click)="toggleInput('showSearchStatus')">search</mat-icon>
                                        <ng-container *ngIf="!showSearchStatus; else InputTemplateStatus">
                                            Status
                                        </ng-container>
                                        <ng-template #InputTemplateStatus>
                                            <input #searchInputStatus (input)="updateFilterValue('status', $event)" (click)="$event.stopPropagation()" placeholder="Search Status">
                                        </ng-template>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="contractNumber">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="header-container">
                                    <div mat-sort-header class="header-cell">
                                        <mat-icon (click)="$event.stopPropagation()" (click)="toggleInput('showSearchContractNumber')">search</mat-icon>
                                        <ng-container *ngIf="!showSearchContractNumber; else inputTemplateContractNumber">
                                            Contract
                                        </ng-container>
                                        <ng-template #inputTemplateContractNumber>
                                            <input #searchInputContractNumber (input)="updateFilterValue('contractNumber', $event)" (click)="$event.stopPropagation()" placeholder="Search Contracts">
                                        </ng-template>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.contractNumber}} </td>
                        </ng-container>

                        <ng-container matColumnDef="billPeriod">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="header-container">
                                    <div mat-sort-header class="header-cell">
                                        <mat-icon (click)="$event.stopPropagation()" (click)="toggleInput('showSearchBillingPeriod')">search</mat-icon>
                                        <ng-container *ngIf="!showSearchBillingPeriod; else inputTemplateBillingPeriod">
                                            Bill Period
                                        </ng-container>
                                        <ng-template #inputTemplateBillingPeriod>
                                            <input #searchInputBillingPeriod (input)="updateFilterValue('billPeriod', $event)" (click)="$event.stopPropagation()" placeholder="Search Bill Period">
                                        </ng-template>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{ element.billPeriod | number }} </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef>
                                <div class="header-container">
                                    <div mat-sort-header class="header-cell">
                                        <mat-icon (click)="$event.stopPropagation()" (click)="toggleInput('showSearchAmount')">search</mat-icon>
                                        <ng-container *ngIf="!showSearchAmount; else inputTemplateAmount">
                                            Amount
                                        </ng-container>
                                        <ng-template #inputTemplateAmount>
                                            <input #searchInputAmount (input)="updateFilterValue('amount', $event)" (click)="$event.stopPropagation()" placeholder="Search Amounts">
                                        </ng-template>
                                    </div>
                                </div>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{ element.amount | currency }} </td>
                        </ng-container>
        
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell id="header-action-cell" *matHeaderCellDef> Actions <mat-icon id="multi-action" (click)="openMultiRowWriteoffActionsModal()" matTooltip="Multi Action" fontIcon="sort"></mat-icon> <mat-icon id="clear-filters" (click)="clearFilters()" matTooltip="Clear Filters" fontIcon="clear"></mat-icon></th>
                            <td mat-cell *matCellDef="let element">
                                <div class="action-icons">
                                    <mat-icon *ngIf="isAdmin" [ngClass]="{'status-denied': element.status === 'DENIED'}" (click)="confirmWriteoffStatusUpdate(element, true)" matTooltip="Deny" fontIcon="cancel" class="deny-writeoff-icon"></mat-icon>
                                    <mat-icon *ngIf="isAdmin" [ngClass]="{'status-approved': element.status === 'APPROVED'}"(click)="confirmWriteoffStatusUpdate(element, false)" matTooltip="Approve" fontIcon="check" class="approve-writeoff-icon"></mat-icon>
                                    <a [routerLink]="['/writeoff/', element.id]" matTooltip="Open Writeoff"><mat-icon>edit</mat-icon></a>
                                </div>
                            </td>
                          </ng-container>
            
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator class="mat-paginator-sticky" #paginator [pageSize]=25 [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
            </ng-template>
    </div>
</div>