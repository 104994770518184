import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { Report } from '../models/report';
import { Writeoff } from '../models/writeoff';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver-es';
import { MatTableDataSource } from '@angular/material/table';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private readonly httpClient: HttpClient, private readonly router: Router, private readonly configService: ConfigService) { }

  private apiUrl = this.configService.get('WriteOffApiUrl');

  getReportDetails(id: string): Observable<Report> {
    return this.httpClient.get<Report>(`${this.apiUrl}/report/${id}`);
  }

  getWriteoffsForReport(id: string): Observable<Writeoff[]> {
    return this.httpClient.get<Writeoff[]>(`${this.apiUrl}/writeoff/report/${id}`);
  }

  applyReport(id: string): Observable<string> {
    return this.httpClient.post(`${this.apiUrl}/writeoff/report/apply/${id}`, null, { responseType: 'text' });
  }

  updateReportStatus(report: Report, status: string): Observable<boolean> {
    report.status = status;
    return this.httpClient.patch<boolean>(`${this.apiUrl}/report/${report.id}`, report);
  }
  
  downloadReportToExcel(dataSource: MatTableDataSource<Writeoff>) {
    const worksheet = utils.json_to_sheet(dataSource.data.map(({subscriberId, status, contractNumber, billPeriod, amount, type, id}) => ({
      subscriberId,
      status,
      contractNumber,
      billPeriod,
      amount,
      type,
      id
    })));

    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheet, 'Writeoffs');

    const wbout = write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(blob, 'Writeoffs.xlsx');
  }

  async createReport(startDate: string, endDate: string, type: string): Promise<void> {
    const postData = {
      "startDate": startDate,
      "endDate": endDate,
      "writeoffType": type,
    };
 
    const result: any = await firstValueFrom(this.httpClient.post(`${this.apiUrl}/report`, postData));

    this.router.navigate(['/report', result['id']]);
  }
}
