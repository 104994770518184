import { Component, ElementRef, QueryList, ViewChild, ViewChildren, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { switchMap } from 'rxjs';

import { MultiActionModalComponent } from '../modals/multi-action-modal/multi-action-modal.component';
import { LoadingProgressModalComponent } from '../modals/loading-progress-modal/loading-progress-modal.component';
import { WriteoffService } from '../services/writeoff.service';
import { ReportService } from '../services/report.service';
import { AuthService } from '../services/auth.service';
import { WriteOffStatus } from '../enums/writeoff-status-enum';
import { Report } from '../models/report';
import { Writeoff } from '../models/writeoff';
import { IToggleStates } from '../models/toggle-states'
import { ConfigService } from '../services';


@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss'],
})
export class ReportDetailsComponent implements OnInit {
  reportDetails!: Report;
  reportId!: string;

  selection = new SelectionModel<Writeoff>(true, []);
  dataSource!: MatTableDataSource<Writeoff>;
  displayedColumns: string[] = ['select', 'subscriberId', 'status', 'contractNumber', 'billPeriod', 'amount', 'actions'];

  isLoadingReportDetails: boolean = true;
  isLoadingWriteoffs: boolean = true;
  isTopSectionCollapsed: boolean = false;
  isReportDenied: boolean = false;
  loadingDataError: boolean = false;
  isAdmin: boolean = false;

  showSearchSubscriberId = false;
  showSearchStatus = false;
  showSearchContractNumber = false;
  showSearchBillingPeriod = false;
  showSearchAmount = false;

  subscriberIdFilter = '';
  statusFilter = '';
  contractNumberFilter = '';
  billingPeriodFilter = '';
  amountFilter = '';

  totalNumberOfApprovedWriteoffs: number = 0;
  totalSumOfApprovedWriteoffs: number = 0;

  constructor(private route: ActivatedRoute, 
              private readonly reportService: ReportService, 
              private readonly writeoffService: WriteoffService,
              private dialog: MatDialog,
              private authService: AuthService,
              private configService: ConfigService) { }

  @ViewChild('paginator') set paginator(paginator: MatPaginator) {
    if (!this.dataSource) { return; }
    this.dataSource.paginator = paginator;
  };
  @ViewChild(MatSort) set sort(sort: MatSort) {
    if (!this.dataSource) { return; }
    this.dataSource.sort = sort;
  };

  @ViewChildren('searchInputSubscriberId') searchInputSubscriberId!: QueryList<ElementRef>;
  @ViewChildren('searchInputStatus') searchInputStatus!: QueryList<ElementRef>;
  @ViewChildren('searchInputContractNumber') searchInputContractNumber!: QueryList<ElementRef>;
  @ViewChildren('searchInputBillingPeriod') searchInputBillingPeriod!: QueryList<ElementRef>;
  @ViewChildren('searchInputAmount') searchInputAmount!: QueryList<ElementRef>;

  async ngOnInit() {
    this.route.params.subscribe(params => {this.reportId = params['id'];});
    this.isAdmin = await this.authService.hasRole(this.configService.get('AdminRole'));
    this.reportService.getReportDetails(this.reportId).pipe(
      switchMap((data: Report) => {
        this.reportDetails = data;
        this.isLoadingReportDetails = false;
        this.isReportDenied = this.reportDetails.status === WriteOffStatus.DENIED;
        return this.reportService.getWriteoffsForReport(this.reportId);
    })
    ).subscribe({
      next: (data) => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.data.forEach(writeoff => writeoff.amount = parseFloat(Number(writeoff.amount).toFixed(2)));
        this.isLoadingWriteoffs = false;
  
        this.totalNumberOfApprovedWriteoffs = this.getTotalNumberOfApprovedWriteoffs();
        this.totalSumOfApprovedWriteoffs = this.getTotalSumOfApprovedWriteoffs();

        this.setupFilterPredicate();
      },
      error: (error) => {
        alert('Error getting writeoffs.');
        this.loadingDataError = true;
        console.error('Error getting writeoffs:', error);
      }
    });
  }

  getTotalNumberOfApprovedWriteoffs() {
    return this.dataSource.data.filter(writeoff => writeoff.status === WriteOffStatus.APPROVED).length;
  }

  getTotalSumOfApprovedWriteoffs() {
    return this.dataSource.data
        .filter(writeoff => writeoff.status === WriteOffStatus.APPROVED)
        .reduce((acc, writeoff) => acc + (Number(writeoff.amount) || 0), 0);
  }

  toggleInput(column: keyof IToggleStates) {
    this[column] = !this[column];

    this.focusInput(column, this[column]);
  }

  focusInput (columnName: string, isToggleOn: boolean) {
    if (!isToggleOn) {
      return;
    }
    
    let inputToFocus: QueryList<ElementRef> | null = null;
    
    switch (columnName) {
        case 'showSearchSubscriberId':
            inputToFocus = this.searchInputSubscriberId;
            break;
        case 'showSearchStatus':
            inputToFocus = this.searchInputStatus;
            break;
        case 'showSearchContractNumber':
            inputToFocus = this.searchInputContractNumber;
            break;
        case 'showSearchBillingPeriod':
            inputToFocus = this.searchInputBillingPeriod;
            break;
        case 'showSearchAmount':
            inputToFocus = this.searchInputAmount;
            break;
    }

    if (inputToFocus) {
      setTimeout(() => inputToFocus?.first.nativeElement.focus(), 0);
    }
  }

  clearFilters() {
    this.subscriberIdFilter = '';
    this.statusFilter = '';
    this.contractNumberFilter = '';
    this.billingPeriodFilter = '';
    this.amountFilter = '';

    if (this.showSearchSubscriberId) {
      this.toggleInput('showSearchSubscriberId');
    }
    if (this.showSearchStatus) {
      this.toggleInput('showSearchStatus');
    }  
    if (this.showSearchContractNumber) {
      this.toggleInput('showSearchContractNumber');
    }
    if (this.showSearchBillingPeriod) {
      this.toggleInput('showSearchBillingPeriod');
    }
    if (this.showSearchAmount) {
      this.toggleInput('showSearchAmount');
    }

    this.applyFilter();
  }

  toggleTopSection() {
    this.isTopSectionCollapsed = !this.isTopSectionCollapsed;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;

    return Number(numSelected) == Number(this.dataSource.filteredData.length);
  }

  toggleAllRows() {
    if(this.isAllSelected()) {
      return this.selection.clear();
    }
    this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  openMultiRowWriteoffActionsModal(): void {
    if (this.selection.selected.length === 0) {
      return alert('Please select at least one writeoff to approve or deny.');
    }
    const dialogRef = this.dialog.open(MultiActionModalComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      switch(result) {
        case true:
          return this.approveOrDenySelectedWriteoffs();
        case false:
          return this.approveOrDenySelectedWriteoffs(true);
        default:
          return;
      }
    });
  }

  openLoadingProgressModal(): void {
    this.dialog.open(LoadingProgressModalComponent, {
      width: '250px'
    });
  }

  closeLoadingProgressModal(): void {
    this.dialog.closeAll();
  }

  downloadToExcel() {
    this.reportService.downloadReportToExcel(this.dataSource);
  }

  confirmReportStatusUpdate(deny: boolean = false) {
    const updateType = deny ? 'deny' : 'undeny';
    const userConfirmed = confirm(`Are you sure you want to ${updateType} this report?`);
    if (!userConfirmed) {
      return console.log(`${updateType} canceled`);
    }
    this.reportService.updateReportStatus(this.reportDetails, deny ? WriteOffStatus.DENIED : WriteOffStatus.CREATED).subscribe({
      next: () => {
        return this.isReportDenied = deny;
      },
      error: (error) => {
        alert(`Error ${updateType}ing report.`);
        console.error(`Error ${updateType}ing report:`, error);
      }
    });
  }

  confirmWriteoffStatusUpdate(writeoff: Writeoff, deny: boolean = false) {
    const updateType = deny ? 'deny' : 'approve';
    const userConfirmed = confirm(`Are you sure you want to ${updateType} this writeoff?`);
    if (!userConfirmed) {
      return console.log(`writeoff ${updateType} canceled`);
    }
    this.writeoffStatusUpdate(writeoff, deny);
    this.updateWriteoffStatusInDataSource(writeoff, deny ? WriteOffStatus.DENIED : WriteOffStatus.APPROVED)
  }

  async approveOrDenySelectedWriteoffs(deny: boolean = false) {
    this.openLoadingProgressModal();
    try {
      for (const row of this.selection.selected) {
        await this.writeoffStatusUpdate(row, deny);
        await this.updateWriteoffStatusInDataSource(row, deny ? WriteOffStatus.DENIED : WriteOffStatus.APPROVED);
      }
      this.closeLoadingProgressModal();
      this.selection.clear();
    } catch (error) {
      console.error(error);
      alert(`Error ${deny ? 'denying' : 'approving'} writeoff.`);
      this.closeLoadingProgressModal();
      this.selection.clear();
    }
  }

  writeoffStatusUpdate(writeoff: Writeoff, deny: boolean = false, ) {
    return new Promise<void>((resolve, reject) => {
      this.writeoffService.updateWriteoffStatus(writeoff, deny ? WriteOffStatus.DENIED : WriteOffStatus.APPROVED).subscribe({
        error: (error) => {alert(`Error ${deny ? 'denying' : 'approving'} writeoff.`); console.log(error); reject(error);},
        complete: () => {resolve();}
      });
    });
  }

  async updateWriteoffStatusInDataSource(writeoff: Writeoff, status: string) {
    const index = this.dataSource.data.findIndex(w => w.id === writeoff.id);
    if (index !== -1) {
      this.dataSource.data[index] = { ...this.dataSource.data[index], status: status };
      this.dataSource.data = [...this.dataSource.data];
    }
    this.totalNumberOfApprovedWriteoffs = this.getTotalNumberOfApprovedWriteoffs();
    this.totalSumOfApprovedWriteoffs = this.getTotalSumOfApprovedWriteoffs();
  }

  setupFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter) => {
      const filterObject = JSON.parse(filter);

      const subscriberIdMatch = !filterObject.subscriberId || data.subscriberId.toString().toLowerCase().includes(filterObject.subscriberId.toLowerCase());
      const statusMatch = !filterObject.status || data.status.toString().toLowerCase().includes(filterObject.status.toLowerCase());
      const contractNumberMatch = !filterObject.contractNumber || data.contractNumber.toString().toLowerCase().includes(filterObject.contractNumber.toLowerCase());
      const billingPeriodMatch = !filterObject.billPeriod || data.billPeriod.toString().toLowerCase().includes(filterObject.billPeriod.toLowerCase());
      const amountMatch = !filterObject.amount|| data.amount.toFixed(2).toString().toLowerCase().includes(filterObject.amount.toLowerCase());
    
      return subscriberIdMatch && statusMatch && contractNumberMatch && billingPeriodMatch && amountMatch;
    };
  }

  updateFilterValue(column: string, event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;

    switch(column) {
        case 'subscriberId':
            this.subscriberIdFilter = filterValue;
            break;
        case 'status':
            this.statusFilter = filterValue;
            break;
        case 'contractNumber':
            this.contractNumberFilter = filterValue;
            break;
        case 'billPeriod':
            this.billingPeriodFilter = filterValue;
            break;
        case 'amount':
            this.amountFilter = filterValue;
            break;
    }

    this.applyFilter();
  }

  applyFilter(): void {
    const combinedFilter = {
        subscriberId: this.subscriberIdFilter,
        status: this.statusFilter,
        contractNumber: this.contractNumberFilter,
        billPeriod: this.billingPeriodFilter,
        amount: this.amountFilter
    };

    this.dataSource.filter = JSON.stringify(combinedFilter);
  }

  checkAllStatuses() {
    const hasMatch = this.dataSource.data.some(writeoff => writeoff.status !== WriteOffStatus.APPROVED && writeoff.status !== WriteOffStatus.DENIED);

    if (hasMatch) {
      alert('All writeoffs must be approved or denied before the report can be Applied.');
      return false;
    }
    return true;
  }

  applyReport() {
    const validReport = this.checkAllStatuses();
    if (!validReport) {
      return;
    }
    this.openLoadingProgressModal();
    this.reportService.applyReport(this.reportId).subscribe({
      next: () => {
        this.closeLoadingProgressModal();
        this.ngOnInit();
        alert('Report applied successfully.');
      },
      error: (error) => {
        this.closeLoadingProgressModal();
        alert('Error applying report.');
      }
    });
  }
}