import { Injectable } from '@angular/core';
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class MsalConfigService {
  constructor() {}

  static MSALInstanceFactory(configService: ConfigService): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: configService.get('AzureClientId'),
        authority: `https://login.microsoftonline.com/${configService.get('AzureTenantId')}`,
        redirectUri: '/auth',
      },
    });
  }

  static MsalGuardConfigFactory(configService: ConfigService): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: MsalConfigService.scopes(configService),
      },
    };
  }

  static MSALInterceptorConfigFactory(configService: ConfigService): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(configService.get('WriteOffApiUrl'), MsalConfigService.scopes(configService));

    return {
      interactionType: InteractionType.Popup,
      protectedResourceMap,
    };
  }

  private static scopes(configService: ConfigService): string[] {
    return configService.get('WriteOffApiScopes')?.map(
      (scope: string) => {
        return `api://${configService.get('WriteOffApiClientId')}/${scope}`;
      }
    );
  }
}
