import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AuthGuard } from './auth.guard';
import { ReportDetailsComponent } from './report-details/report-details.component';
import { CreateReportComponent } from './create-report/create-report.component';
import { WriteoffReportComponent } from './writeoff-report/writeoff-report.component';  
import { WriteoffDetailsComponent } from './writeoff-details/writeoff-details.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: 'auth',
    component: MsalRedirectComponent,
  },
  {
    path: '',
    component: WriteoffReportComponent, 
    canActivate: [AuthGuard],   
  }, 
  {
    path: 'report/:id',
    component: ReportDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'writeoff/:id',
    component: WriteoffDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create',
    component: CreateReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,   
  },
  {
    path: '**',
    redirectTo: '',
  }
];  

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],  
})
export class AppRoutingModule {}