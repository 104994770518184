<div class="form-container">
    <form [formGroup]="writeoffDetailsForm">
        <div id="loading">
            <mat-spinner *ngIf="!loadingDataError && isLoading"></mat-spinner>
        </div>
        <div class="error-message" *ngIf="loadingDataError">
            <p>There was an error loading the report details. Please try again later.</p>
        </div>
        <div *ngIf="!isLoading && !loadingDataError">
            <a [routerLink]="['/report/', this.writeoff.reportId]" matTooltip="Back to report"><mat-icon>arrow_back</mat-icon></a>
            <mat-icon *ngIf="isAdmin" id="edit" (click)="edit()" matTooltip="Edit Writeoff">edit</mat-icon>
            <div class="form-group">
                <div class="column-labels">
                    <label for="subscriberId">Subscriber ID: </label>
                </div>
                <div class="column-values">
                    <p>{{ this.writeoff.subscriberId }}</p>
                </div>
            </div>
            <div class="form-group">
                <div class="column-labels">
                    <label for="contractNumber">Contract Number: </label>
                </div>
                <div class="column-values">
                    <input *ngIf="editable" type="text" class="form-control" id="contractNumber" placeholder="Contract Number" formControlName="contractNumber">
                    <p *ngIf="!editable" >{{ this.writeoff.contractNumber }}</p>
                </div>
            </div>
            <div class="form-group">
                <div class="column-labels">
                    <label for="type">Type: </label>
                </div>
                <div class="column-values">
                    <p>{{ this.writeoff.type }}</p>
                </div>
            </div>
            <div class="form-group">
                <div class="column-labels">
                    <label for="amount">Amount: </label>
                </div>
                <div class="column-values">
                    <input *ngIf="editable" type="text" class="form-control" id="amount" placeholder="Amount" formControlName="amount" pattern="^\d*\.?\d*$" (keypress)="numberDecimalValidationService.numberAndDecimalOnly($event)">

                    <p *ngIf="!editable">{{ this.writeoff.amount | currency:'USD' }}</p>
                </div>
            </div>
            <div class="form-group">
                <div class="column-labels">
                    <label for="billPeriod">Bill Period: </label>
                </div>
                <div class="column-values">
                    <input *ngIf="editable" type="number" class="form-control" id="billPeriod" placeholder="Bill Period" formControlName="billPeriod">
                    <p *ngIf="!editable">{{ this.writeoff.billPeriod}}</p>
                </div>                
            </div>
            <div class="form-group">
                <div class="column-labels">
                    <label for="status">Status: </label>
                </div>
                <div class="column-values">
                    <select *ngIf="editable" class="form-control" id="status" placeholder="Status" formControlName="status">
                        <option value={{statusEnum.CREATED}}>Created</option>
                        <option value={{statusEnum.APPROVED}}>Approved</option>
                        <option value={{statusEnum.DENIED}}>Denied</option>
                        </select>
                    <p *ngIf="!editable">{{ this.writeoff.status }}</p>
                </div>                
            </div>
            <div class="form-group">
                <div class="column-labels">
                    <label for="updatedAt">Updated: </label>
                </div>
                <div class="column-values">
                    <p>{{ this.writeoff.updatedAt | date:'medium' }}</p>
                </div>                
            </div>
            <div class="form-group">
                <div class="column-labels">
                    <label for="lastUpdatedBy">Last Updated By: </label>
                </div>
                <div class="column-values">
                    <p>{{ this.writeoff.lastUpdatedBy.name }}</p>
                </div>                
            </div>
            <div class="form-group">
                <div class="column-labels">
                    <label for="reportId">Report ID: </label>
                </div>
                <div class="column-values">
                    <p>{{ this.writeoff.reportId }}</p>
                </div>
            </div>
            <div id="submit-button">
                <button type="submit" *ngIf="writeoffDetailsForm.valid && editable" (click)="updateWriteoff()" mat-raised-button color="primary">Submit</button>
            </div>
        </div>
    </form>
</div>