import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { WriteoffService } from '../services/writeoff.service';
import { Writeoff } from '../models/writeoff';
import { WriteOffStatus } from '../enums/writeoff-status-enum';
import { NumberDecimalValidationService } from '../services/number-decimal-validation.service';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-writeoff-details',
  templateUrl: './writeoff-details.component.html',
  styleUrls: ['./writeoff-details.component.scss']
})
export class WriteoffDetailsComponent implements OnInit {
  writeoffDetailsForm!: FormGroup;
  writeoffId!: string;
  writeoff!: Writeoff;
  isLoading: boolean = true;
  loadingDataError: boolean = false;
  editable: boolean = false;
  statusEnum = WriteOffStatus;
  isAdmin: boolean = false;

  constructor(private route: ActivatedRoute, 
              private readonly writeoffService: WriteoffService,
              private formBuilder: FormBuilder,
              public numberDecimalValidationService: NumberDecimalValidationService,
              private authService: AuthService, 
              private configService: ConfigService ) { 
                this.writeoffDetailsForm = this.formBuilder.group({
                  subscriberId: ['', Validators.required],
                  contractNumber: ['', Validators.required],
                  type: ['', Validators.required],
                  amount: ['', Validators.required],
                  billPeriod: ['', Validators.required],
                  status: ['', Validators.required],
                  updatedAt: ['', Validators.required],
                  lastUpdatedBy: [''],
                  reportId: ['', Validators.required],
                  id: ['']
                });
              }

  async ngOnInit() {
    try {
      this.isAdmin = await this.authService.hasRole(this.configService.get('AdminRole'));
      this.route.params.pipe(
        switchMap(params => this.writeoffService.getWriteoffDetails(params['id'])))
        .subscribe({
         next: (data: Writeoff) => {
            this.writeoff = data;
            this.writeoff.amount = parseFloat(Number(this.writeoff.amount).toFixed(2));
            this.writeoffDetailsForm.patchValue(this.writeoff);
            this.isLoading = false;
            this.editable = false;
         },
         error: () => {this.loadingDataError = true; this.isLoading = false;},
      });
    } catch (error) {
      this.loadingDataError = true;
      this.isLoading = false;
    }
  }

  edit() {
    this.editable = !this.editable;
    return this.editable;
  }

  updateWriteoff() {
    const writeoff = this.writeoffDetailsForm.value;
    if(writeoff.status === WriteOffStatus.APPROVED) {
      return alert('Writeoff has already been approved. Please change status to Created via Report page.')
    } 
    this.writeoffService.updateWriteoff(writeoff).subscribe(() => {
      this.ngOnInit();
    });
  }
}