import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrls: ['./create-report.component.scss']
})
export class CreateReportComponent implements OnInit {
  constructor(private reportService: ReportService, 
              private formBuilder: FormBuilder, 
              private authService: AuthService, 
              private configService: ConfigService) { }

  newReportForm!: FormGroup;
  isLoading: boolean = false;
  isAdmin: boolean = false;

  async ngOnInit() {
    this.newReportForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      type: ['', Validators.required]
    }, { validator: this.dateLessThan('startDate', 'endDate') });
    this.isAdmin = await this.authService.hasRole(this.configService.get('AdminRole'));
  }

  async onSubmit() {
    console.log(this.newReportForm.value);
    const startDate = this.newReportForm.value.startDate;
    const endDate = this.newReportForm.value.endDate;
    const type = this.newReportForm.value.type;

    if( startDate && endDate && type ) {
      this.isLoading = true;
      try {
        await this.reportService.createReport(startDate, endDate, type);
      } catch {
        alert("There was an error creating the report. Please try again. If the issue persists, please contact support.");
        this.isLoading = false;
      }
    }
  }

  dateLessThan(startFieldName: string, endFieldName: string) {
    return (formGroup: FormGroup) => {
      const startControl = formGroup.controls[startFieldName];
      const endControl = formGroup.controls[endFieldName];
  
      if (startControl && endControl && startControl.value && endControl.value) {
        if (new Date(startControl.value) > new Date(endControl.value)) {
          return { dateLessThan: true };
        }
      }
      return null;
    };
  }
  
}
