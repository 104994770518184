import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberDecimalValidationService {

  constructor() { }

  public numberAndDecimalOnly(event: KeyboardEvent): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      return false;
    }
    return true;
  }
}
