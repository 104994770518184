import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  static get config() {    
    return (window as any).appconfig;
  }

  get config() {
    return ConfigService.config;
  }

  get(key: string): any {    
    return this.config[key];
  }

  getString(key: string): string {
    return this.get(key)?.toString();
  }
}
