import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Writeoff } from '../models/writeoff';
import { DecimalPipe } from '@angular/common';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class WriteoffService {
  constructor(private readonly httpClient: HttpClient, private readonly decimalPipe: DecimalPipe, private readonly configService: ConfigService) { }
  
  private apiUrl = this.configService.get('WriteOffApiUrl');

  updateWriteoffStatus(writeoff: Writeoff, status: string): Observable<boolean> {
    const apiWriteoff = {
      ...writeoff,
      status: status,
      amount: this.decimalPipe.transform(writeoff.amount, '1.2-2')?.replace(',', '')
    };
  
    return this.httpClient.patch<boolean>(`${this.apiUrl}/writeoff/${writeoff.id}`, apiWriteoff);
  }

  updateWriteoff(writeoff: Writeoff): Observable<boolean> {
    const apiWriteoff = {
      ...writeoff,
      amount: this.decimalPipe.transform(writeoff.amount, '1.2-2')
    };
    return this.httpClient.patch<boolean>(`${this.apiUrl}/writeoff/${writeoff.id}`, apiWriteoff);
  }

  getWriteoffDetails(id: string): Observable<Writeoff> {
    return this.httpClient.get<Writeoff>(`${this.apiUrl}/writeoff/${id}`);
  }
}
